.card {
    background-color: rgba(255,255,255, 0.08);
    border-radius: 0.125rem;
    flex: 1;
    margin-bottom: 1rem;
}

.card-title-wrapper{
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.4);
    border-bottom: solid 1px rgba(255, 255, 255, 0.16);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.card-title{
    margin-right: 1rem
}

.card-pred {
    margin-left: 1rem;
    font-weight: 700;
}

.card-body{
    padding: 1rem;
}