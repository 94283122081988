.prediction {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    border-bottom: solid 1px rgba(255, 255, 255, 0.08);
    font-weight: 700;
}

.prediction-row:nth-child(1){
    width: 2.25rem;
    font-size: 0.75rem;
}
.prediction-row:nth-child(2){
    width: 4rem;
}
.prediction-row:nth-child(3){
    flex:1;
}
.prediction-row:nth-last-child(2){
    margin-left: 0.5rem;
}
.prediction-row:nth-last-child(1){
    margin-left: 0.5rem;
}