.prediction-confidence {
    flex: 1;
    height: 0.75rem;
    border: solid 2px rgba(0, 0, 0, 0.4);
    border-radius: 0.5rem;
    overflow: hidden;
}

.prediction-confidence-inner {
    background-color: red;
    height: 100%;
    width: 10rem;
}